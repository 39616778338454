export const USER_NAVS =  [
  {
    title: 'Akun Saya',
    position: ' ',
    isButtonStyleType: true,
    link: '#',
    ordering: 10,
    childItems: [
      {
        title: 'Pengajuan',
        iconName: null,
        link: '/accounts/applications',
        position: 'right',
      },
      {
        title: 'Polis Saya',
        iconName: null,
        link: '/accounts/policy',
        position: 'right',
      },
      {
        title: 'Profil',
        iconName: null,
        link: '/account/profile',
        position: 'right',
      },
      {
        title: 'Keluar',
        link: '#',
        position: 'right',
      },
    ],
  },
];

export const LOGOUT_NAV = {
  title: "Keluar",
  link: "",
  position: "right",
};
