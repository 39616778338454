import React from 'react';
import { useNavbarContext } from '../../Navbar';
import './NavLink.scss';

export const NavLink = (props) => {
  const {
    item,
    onMouseOver,
    onMouseLeave,
    isWithStyle = false,
    isSelected = false,
    isDesktop = false,
    parentHasDropdown = false,
    level,
  } = props;
  const { linkComponent, logoutAction } = useNavbarContext();

  if (!linkComponent && item.type !== 'spa') {
    return (
      <LinkElement
        item={item}
        isWithStyle={isWithStyle}
        isSelected={isSelected}
        isDesktop={isDesktop}
        parentHasDropdown={parentHasDropdown}
        level={level}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        logoutAction={logoutAction}
      />
    );
  }

  return linkComponent(
    <LinkElement
      item={item}
      isWithStyle={isWithStyle}
      isSelected={isSelected}
      isDesktop={isDesktop}
      parentHasDropdown={parentHasDropdown}
      level={level}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      logoutAction={logoutAction}
    />,
    item
  );
};

const LinkElement = (props) => {
  const {
    item,
    onMouseOver,
    onMouseLeave,
    isSelected = false,
    isDesktop = false,
    parentHasDropdown = false,
    level,
    logoutAction,
  } = props;

  var itemClass;

  const switchClass = (level) => {
    switch (level) {
      case 0:
        itemClass = 'primary_nav_link';
        break;
      case 1:
        itemClass = 'secondary_nav_link';
        break;
      case 2:
        itemClass = 'tertiary_nav_link';
        break;
      default:
        itemClass = '';
    }

    return itemClass;
  };

  return parentHasDropdown ? (
    <div
      className={`${switchClass(level)} ${isSelected && 'selected'} ${item.isButtonStyleType ? 'style_button_account' : ''
        }`}
      onTouchEnd={isDesktop ? null : isSelected ? onMouseLeave : onMouseOver}
      onMouseOver={isDesktop ? onMouseOver : null}
    >
      {item.isButtonStyleType && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          className={'style_button_account'}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20C4 11.1634 11.1634 4 20 4C28.8366 4 36 11.1634 36 20ZM24 14C24 16.2091 22.2091 18 20 18C17.7909 18 16 16.2091 16 14C16 11.7909 17.7909 10 20 10C22.2091 10 24 11.7909 24 14ZM19.9999 22C15.9648 22 12.4879 24.3899 10.9075 27.8314C13.1081 30.3841 16.3653 32 20 32C23.6346 32 26.8917 30.3841 29.0923 27.8316C27.5119 24.39 24.035 22 19.9999 22Z"
          />
        </svg>
      )}

      <span className={item.isButtonStyleType ? 'style_button_account' : ''}
        style={{ fontFamily: "Open Sans", fontWeight: 400 }}>
        {item.title}
      </span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="16"
        viewBox="0 0 17 16"
        fill="none"
        className={item.isButtonStyleType ? 'style_button_account' : ''}
      >
        <path d="M8.55553 9.98334C8.46664 9.98334 8.38064 9.96667 8.29753 9.93334C8.21398 9.9 8.14442 9.85556 8.08887 9.8L5.0222 6.73334C4.89998 6.61111 4.83887 6.45556 4.83887 6.26667C4.83887 6.07778 4.89998 5.92222 5.0222 5.8C5.14442 5.67778 5.29998 5.61667 5.48887 5.61667C5.67776 5.61667 5.83331 5.67778 5.95553 5.8L8.55553 8.4L11.1555 5.8C11.2778 5.67778 11.4333 5.61667 11.6222 5.61667C11.8111 5.61667 11.9666 5.67778 12.0889 5.8C12.2111 5.92222 12.2722 6.07778 12.2722 6.26667C12.2722 6.45556 12.2111 6.61111 12.0889 6.73334L9.0222 9.8C8.95553 9.86667 8.88331 9.91378 8.80553 9.94134C8.72776 9.96934 8.64442 9.98334 8.55553 9.98334Z" />
      </svg>
    </div>
  ) : (
    <a
      href={item.link}
      title={item.title}
      className={`${switchClass(level)} ${isSelected && 'selected'}`}
      onClick={(e) => {
        if (item.type === 'prevent' || item.type === 'spa') {
          e.preventDefault();
        }

        if (item.title === 'Keluar') {
          logoutAction();
          return;
        }

        item.onClick && item.onClick(item);
      }}
    >
      <span>{item.title}</span>
    </a>
  );
};
