import React, { useState, useEffect } from 'react';

import dynamic from 'next/dynamic';
import { Navbar } from './Navbar';
import { useAuth } from '../../providers/Auth';
import { useResponsive } from '../../client/utils/useResponsive';
import { isAPISupported } from '../../client/utils/isAPISupported';
import { MAIN_NAV, USER_NAVS, LOGIN_MENU } from './navs';
import './Navigation.module.scss';

const Navigation = ({ dispatch }) => {
  const { isDesktop } = useResponsive();

  const [clientReady, setClientReady] = useState(false);
  const [shouldShowLogin, setShouldShowLogin] = useState(false);

  const {
    isAuthenticated,
    setAuthenticated,
    setMe,
    setAuthToken,
    setAgent,
    setTSOSupervisor,
    setRegistered,
  } = useAuth();

  const handleLogout = () => {
    dispatch({ type: 'LOG_OUT' });
    dispatch({ type: 'USER_LOGGED_OUT' });

    // clearing auth context
    setMe({});
    setAuthToken('');
    setAuthenticated(false);
    setAgent(false);
    setTSOSupervisor(false);
    setRegistered(false);

    // close the eventSource connection and resets it's value
    if (window.memoziedEventSource) {
      window.memoziedEventSource.close();
      window.memoziedEventSource = undefined;
    }

    // close the eventSource connection and resets it's value
    if (window.instantMessageSource) {
      window.instantMessageSource.close();
      window.instantMessageSource = undefined;
    }

    window.location.href = '/';
  };

  const getLoginModalView = () => {
    const LoginModal = dynamic(() => import('../../client/modules/account/components/LoginModal/LoginModal'));
    return (
      <LoginModal
        shouldShowLogin={shouldShowLogin}
        setShouldShowLogin={setShouldShowLogin}
      />
    )
  };

  const handleLogin = () => {
    if (!isAuthenticated) {
      setShouldShowLogin(true);
    } else {
      window.location.href = '/account/login';
    }
  };

  useEffect(() => {
    LOGIN_MENU.onClick = handleLogin;

    if (isAPISupported('window')) {
      window.handleLogout = handleLogout;
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') setClientReady(true);
  }, [clientReady]);

  return (
    <div>
      <style>
        {
          `
            .login__modal, .registration__background {
              z-index: 9998;
              top: 0;
            }
          `
        }
      </style>
      <Navbar
        menu={MAIN_NAV}
        authenticatedMenu={USER_NAVS}
        loginAction={handleLogin}
        logoutAction={handleLogout}
        isAuthenticated={isAuthenticated}
        logoPath='https://lifepal.co.id/static/images/lifepal-logo-blue-svg.svg'
        isMobile={!isDesktop}
      />
      {shouldShowLogin && getLoginModalView()}
    </div>
  )
};

export default Navigation;
