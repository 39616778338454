import { useState, useEffect } from 'react';

/**
 * Accepts a media query string then uses like '(min-width: 768px)' the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.<br />
 * It also monitor the document changes to detect when it matches or stops matching the media query.<br />
 * Returns the validity state of the given media query.
 *
 */
 export const useMediaQuery = (mediaQuery) => {
  if (typeof window === 'undefined') {
    return false;
  }
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery);
    if (mediaQueryList.matches !== isVerified) {
      setIsVerified(mediaQueryList.matches);
    }
    const documentChangeHandler = () => setIsVerified(mediaQueryList.matches);
    mediaQueryList.addEventListener('change', documentChangeHandler);
    return () => {
      mediaQueryList.removeEventListener('change', documentChangeHandler);
    };
  }, [isVerified, mediaQuery]);

  return isVerified;
};
