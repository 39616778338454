import { useState, useEffect } from "react";
import { useMediaQuery } from "./useMediaQuery";
import { BREAK_POINTS } from "./breakpoint";

export const useResponsive = () => {
  const [isClient, setIsClient] = useState(false);

  const isDesktop = useMediaQuery(BREAK_POINTS.desktop);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsClient(true);
    } 
  }, [isClient]);

  return {
    isDesktop: isClient ? isDesktop : true,
  }
}