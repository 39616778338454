export const NORMAL_MENU = [
  {
    id: 1,
    icon: 'explore',
    text: 'Explore',
    gtmClassName: 'gtm__footerclick',
  },
  {
    id: 2,
    icon: 'tools',
    text: 'Tools',
    gtmClassName: '',
  },
  {
    id: 3,
    icon: 'insurance',
    text: 'Asuransi',
    gtmClassName: '',
  },
  {
    id: 4,
    icon: 'profile',
    text: 'My Lifepal',
    gtmClassName: '',
  },
];

export const LOGIN_MENU = [
  {
    title: "Home",
    link: "/",
    icon: "home"
  },
  {
    title: "Pengajuan",
    link: "/accounts/applications/",
    icon: "application"
  },
  {
    title: "Polis Saya",
    link: "/accounts/policy/",
    icon: "policy"
  },
  {
    title: "Bantuan",
    link: "/bantuan/",
    icon: "help"
  },
  {
    title: "My Lifepal",
    link: "/account/profile/",
    icon: "profile"
  }
]

export const ACCOUNT_PROPS = {
  title: "Buat Akun untuk Konsultasi Asuransi Gratis Sekarang!",
  subtitle: "Dapatkan juga tips keuangan, investasi, dan informasi promo terbaru setiap minggunya",
  imgSrc: '/static/images/footer_account.webp'
}

export const INSURANCE_LINKS = [
  {
    id: 'health',
    link: `/promo/kesehatan/`,
    text: 'Asuransi Kesehatan',
  },
  {
    id: 'auto',
    link: `/promo/mobil/`,
    text: 'Asuransi Mobil',
  },
  {
    id: 'life',
    link: `/promo/jiwa/`,
    text: 'Asuransi Jiwa',
  },
]

export const TOOLS_PROPS = {
  title: 'Kalkulator Keuangan',
  subtitle:
    'Gunakan kalkulator kami untuk membantu menyelesaikan beragam kebutuhan perencanaan keuanganmu. Hitung dan simulasikan berbagai skenario sesuai kondisi pribadi dan keuanganmu',
  calculatorArticles: [
    {
      title: 'Kalkulator Bunga Deposito dan Simulasi Keuntungannya',
      slug: 'kalkulator-bunga-deposito',
    },
    {
      title: 'Kalkulator Premi Asuransi Mobil',
      slug: 'kalkulator-premi-asuransi-mobil',
    },
    {
      title: 'Kalkulator Menabung Bulanan – Simulasi Kebutuhan Tabungan Berdasarkan Target Akhir',
      slug: 'kalkulator-menabung-bulanan',
    },
    {
      title: 'Kalkulator Kredit Mobil Bunga Flat',
      slug: 'kalkulator-angsuran-kredit-mobil',
    },
    {
      title: '[KUIS] Apa Jenis Mobil Yang Paling Cocok Untuk Kamu Berdasarkan Profil Pribadi?',
      slug: 'kuis-jenis-tipe-mobil-yang-paling-cocok-untuk-kamu',
    },
    {
      title: 'Kalkulator Tarif dan Biaya Listrik',
      slug: 'kalkulator-tarif-dan-biaya-listrik',
    },
    {
      title: 'Kuis Asuransi Jiwa – Jenis Asuransi Jiwa Manakah Yang Paling Cocok Untuk Saya?',
      slug: 'kuis-jenis-asuransi-jiwa-terbaik',
    },
    {
      title: 'Kuis Profil Resiko Investasi – Kenali Profil Risiko Anda',
      slug: 'kuis-profil-resiko-investasi',
    },
    {
      title: 'Kalkulator Uang Pertanggungan Asuransi Jiwa',
      slug: 'kalkulator-uang-pertanggungan',
    },
    {
      title: 'Kalkulator Dana Pensiun untuk Perencanaan Hari Tua',
      slug: 'kalkulator-dana-pensiun',
    },
  ],
};

export const EXPLORE_PROPS = {
  category: {
    name: 'Artikel Terkait',
    description: '',
  },
  relatedPosts: [
    {
      title: 'Cara menghitung BPJS ketenagakerjaan dengan tepat',
      slug: '/media/bpjs-ketenagakerjaan-dan-cara-menghitungnya/',
    },
    {
      title: 'Panduan Asuransi Terbaik dan Cara Memilihnya',
      slug: '/media/panduan-asuransi/',
    },
    {
      title: 'Cara Mengatur Keuangan Pribadi',
      slug: '/media/cara-mengatur-keuangan-pribadi/',
    },
    {
      title: 'Lifepal Ajak Blogger Sosialisasikan Pentingnya Asuransi',
      slug: '/media/melek-asuransi-lifepal/',
    },
    {
      title: 'Apa itu Investasi',
      slug: '/media/apa-dan-bagaimana-cara-berinvestasi/',
    },
  ],
};
