import React, { useState } from 'react';
import { NavList } from '../../DesktopNavbar/DesktopList/NavList';
import './DropdownNav.scss';

export const DropdownNav = (props) => {
  const [hoveredChild, setHoveredChild] = useState(null);
  const {
    menu,
    isSelected = false,
    isDesktop = false,
    isShowChild = false,
    level,
  } = props;

  var itemClass;

  const switchClass = (level) => {
    switch (level) {
      case 1:
        itemClass = 'primary_dropdown_menu';
        break;

      case 2:
        itemClass = 'secondary_dropdown_menu';
        break;

      default:
        itemClass = '';
    }

    return itemClass;
  };

  return (
    <ul
      className={`${switchClass(level)} ${isSelected && 'show'}`}
      onMouseLeave={() => {
        setHoveredChild(undefined);
      }}
    >
      {menu.map((item) => (
        <NavList
          level={level}
          key={item.title}
          item={item}
          isDesktop={isDesktop}
          onMouseOver={() => {
            setHoveredChild(item);
          }}
          onMouseLeave={() => {
            setHoveredChild(undefined);
          }}
          isSelected={hoveredChild && hoveredChild.title === item.title}
        />
      ))}
    </ul>
  );
};
