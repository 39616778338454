import React from 'react';

import { useNavbarContext } from '../../Navbar';
import './NavBrand.scss';

export const NavBrand = () => {
  const { logoPath } = useNavbarContext();

  return (
    <div className="nb-root">
      <a
        href="/" className="img">
        <img
          src={logoPath}
          alt="lifepal-user-logo"
          width="91px"
          height="31px"
        />
      </a>
    </div>
  );
}
