import React, { useEffect, useState, useContext, createContext } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { BottomNavigation } from '@lifepal/lifepal-design-system';
import Footer from './Footer';

import {
  NORMAL_MENU, LOGIN_MENU, ACCOUNT_PROPS,
  INSURANCE_LINKS, TOOLS_PROPS, EXPLORE_PROPS
} from '../shared/bottom.navigation';
import { AGENT_CONSTANTS } from '../client/modules/agents/constants/agent.constants';

import Navigation from './Navigation/Navigation';

import { useAuth } from '../providers/Auth';

import '../styles/components/navbar.scss';
import '../styles/components/bottomNavigation.scss';

import { useResponsive } from '../client/utils/useResponsive';
import useWebView from '../lib/hooks/useWebView'

import navigationConstant from "../public/static/js/navigation.json";

const LayoutContext = createContext({
  showHeader: true,
  setShowHeader: () => { },
});

export const Layout = ({ authToken, children, dispatch }) => {
  const [showHeader, setShowHeader] = useState(true);
  const { isDesktop } = useResponsive();
  const isWebView = useWebView();
  const {
    isAgent,
    setAuthenticated,
    setMe,
    setAuthToken,
    setAgent,
    setTSOSupervisor,
    setRegistered,
  } = useAuth();
  const [isUser, setUser] = React.useState(false);

  const router = useRouter();
  const { query } = router;
  const isBengkel = router.asPath.includes('/bengkel-mobil/');

  const isCartaxAndBengkel =
    ['/pajak-mobil', '/bengkel-mobil', '/bengkel-mobil/[...specification]'].includes(router.pathname);
  const isCekKeuangan = ['/cek-keuangan', '/cek-keuangan-b'].includes(router.pathname);

  const [toggleProfileDropdown, setToggleProfileDropdown] = React.useState(false);

  useEffect(() => {
    if (!showHeader) {
      setShowHeader(true);
    }
    if (isWebView && document) {
      document.body.style.paddingTop = '0 !important';
    }
  }, [isWebView, query]);

  const blockListURL = [
    '/application/[id]',
    '/checkout/[id]',
    '/payment-link/[id]',
    '/application',
    '/checkout',
    '/produk-digital/bpjs',
    '/produk-digital/pln',
    '/produk-digital/mobil-tax',
    '/plans',
    '/compare',
    '/account/login',
    '/account/register',
  ];

  const simpleNavigationURL = [
    ...blockListURL,
    '/penawaran',
    '/search',
    '/plans',
    '/compare',
    '/payment',
    '/ketentuan',
    '/bantuan-zendesk',
    '/dropbox',
    '/pusat-bantuan',
    '/contact-us/[lead_id]'
  ];

  const noNavigationURL = [
    '/dropbox',
  ];

  const historyBack = () => {
    history.back();
  }

  const SimpleNavigation = (props) => {
    const { pathName, shouldShowArrow } = props;
    
    const navigationText = () => {
      switch (pathName) {
        case '/compare':
          return 'Perbandingan';
        case '/application':
          return 'Aplikasi';
        case '/payment-link':
          return 'Pembayaran';
      }
    };

    if (noNavigationURL.includes(pathName)) return <></>;

    return (
      <div className='simpleNavigation'>
        {
          shouldShowArrow && (
            <a
              onClick={historyBack}
              className='simpleNavigation__backBtn'
            >
              <img
                alt='Lifepal Back Button'
                src='/static/images/car-tax/back-btn.png'
              />
            </a>
          )
        }
        {['/compare', '/application', '/payment-link'].includes(pathName) ?
          <div>
            {navigationText()}
          </div> :
          <Link href="/">
            <div className='simpleNavigation__logo'>
              {query && query.mitra === '1' ?
                <img alt='PT AAA' src='/static/images/triple-a-logo.png' />
                :
                <img alt='Lifepal' src='/static/images/lifepal-logo.png' />
              }
            </div>
          </Link>}
      </div>
    );
  }

  const SimpleNavigationBlue = () => {
    return (
      <div
        className={
          classNames(
            'simpleNavigationBlue',
            ['/agents'].includes(router.pathname) ? 'space__between' : 'flex__start'
          )
        }
      >
        <a
          onClick={historyBack}
          className={
            classNames(
              'simpleNavigationBlue__backBtn',
              ['/agents'].includes(router.pathname) && 'header__invisble'
            )
          }
        >
          <i
            className="lifepal-long-arrow-left"
          />
        </a>
        <Link href={['/agents'].includes(router.pathname) ? "/agents" : "/"}>
          <div className='simpleNavigationBlue__logo'>
            <img alt='Lifepal Logo'
              src={
                (isAgent || ['/agents'].includes(router.pathname))
                  ? "/static/images/lifepal-agent.png"
                  : "/static/images/lifepal_logo_white.png"
              }
            />
          </div>
        </Link>
        {
          ['/agents'].includes(router.pathname) && (
            <div className="profile">
              <div
                className="profile__dropdown"
                role="button"
                onClick={() => setToggleProfileDropdown(!toggleProfileDropdown)}
              >
                <i className="lifepal-profile" />
              </div>
              {
                toggleProfileDropdown && (
                  <ul className="profile__menu--list">
                    <li>
                      <Link href="/agents/profile/">
                        <a title="Lifepal Agents">Profil</a>
                      </Link>
                    </li>
                    <li>
                      <a onClick={handleLogout}>Keluar</a>
                    </li>
                  </ul>
                )
              }
            </div>
          )
        }
      </div>
    );
  };

  const handleLogout = () => {
    dispatch({ type: "LOG_OUT" });
    dispatch({ type: "USER_LOGGED_OUT" });

    // clearing auth context
    setMe({});
    setAuthToken("");
    setAuthenticated(false);
    setAgent(false);
    setTSOSupervisor(false);
    setRegistered(false);

    sessionStorage.setItem(
      "agentNavigation",
      JSON.stringify(navigationConstant["agent"])
    );

    // close the eventSource connection and resets it's value
    if (window.memoziedEventSource) {
      window.memoziedEventSource.close();
      window.memoziedEventSource = undefined;
    }

    // close the eventSource connection and resets it's value
    if (window.instantMessageSource) {
      window.instantMessageSource.close();
      window.instantMessageSource = undefined;
    }

    router.push("/");
  };


  const loadNavigation = () => {
    if (isWebView) return null;

    const isAgentRegister = ['/agents/register/[slug]', '/agents/kyc-verification/[id]'].includes(router.pathname);
    const agentNavigation = [
      '/agents', '/search', '/plans',
      '/application/[id]', '/checkout/[id]',
      '/agents/profile', '/agents/orders',
      '/agents/teams', '/agents/status/[id]',
      '/agents/quotations/[id]', '/agents/invite'
    ];

    if (isAgent && agentNavigation.includes(router.pathname)) {
      return <SimpleNavigationBlue />
    }

    if (!isDesktop && !isCartaxAndBengkel && !isAgentRegister) {
      if (simpleNavigationURL.includes(router.pathname)) {
        return <SimpleNavigation pathName={router.pathname} shouldShowArrow={false} />
      }
      else if (isCekKeuangan) {
        return (
          <a
            onClick={historyBack}
            className='simpleNavigation__backBtnWhite'
          >
            <img
              alt='Lifepal Back Button'
              src='/static/images/car-tax/back-btn-white.webp'
            />
          </a>
        )
      }
    }
    else if (isCartaxAndBengkel) return <SimpleNavigationBlue />
    else if (isAgentRegister) {
      return <SimpleNavigation pathName={router.pathname} shouldShowArrow={router.pathname !== "/agents/kyc-verification/[id]"} />
    }
    return <Navigation dispatch={dispatch} />;
  };

  useEffect(() => {
    if (!isBengkel && authToken) {
      const role = AGENT_CONSTANTS.getAgentByToken(authToken);
      if (!role.isInternalAgent && !role.isExternalAgent && !role.isTSOSupervisor) {
        setUser(true);
      }
    }
  }, [authToken]);

  const loadBottomNavigation = () => {
    const newBlockListURL = [...blockListURL, '/pajak-mobil'];

    if (
      (authToken && isUser)
      && !newBlockListURL.includes(router.pathname)
      && !router.pathname.includes('/agents')
    ) {
      return (
        <BottomNavigation
          NextLink={Link}
          activePath={router.asPath}
          isLoggedIn={true}
          normalMenu={NORMAL_MENU} //menu when user isn't logged in
          loginMenu={LOGIN_MENU}
          accountProps={ACCOUNT_PROPS}
          insuranceLinks={INSURANCE_LINKS}
          toolsProps={TOOLS_PROPS}
          exploreProps={EXPLORE_PROPS}
        />
      )
    } else if (isBengkel) {
      return (
        <BottomNavigation
          NextLink={Link}
          activePath={router.asPath}
          isLoggedIn={authToken ? true : false}
          normalMenu={NORMAL_MENU} //menu when user isn't logged in
          loginMenu={LOGIN_MENU}
          accountProps={ACCOUNT_PROPS}
          insuranceLinks={INSURANCE_LINKS}
          toolsProps={TOOLS_PROPS}
          exploreProps={EXPLORE_PROPS}
        />
      )
    } else return "";
  };

  return (
    <LayoutContext.Provider
      value={{
        showHeader,
        setShowHeader,
      }}
    >
      {showHeader && loadNavigation()}
      {children}
      {!authToken && <Footer pathName={router.pathname} isBengkel={isBengkel} />}
      {/* This loadBottomNav should be in the last*/}
      {!isDesktop && loadBottomNavigation()}
    </LayoutContext.Provider>
  )
};

export const useLayoutContext = () => useContext(LayoutContext);
