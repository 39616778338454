import { useRouter } from "next/router";
import { useEffect, useState } from "react";

const useWebView = () => {
  const router = useRouter();
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    if (Number(router?.query?.is_mobile) === 1) {
      setIsWebView(true);

      sessionStorage.setItem("is_mobile", "1");
    } else if (sessionStorage.getItem("is_mobile") === "1") {
      setIsWebView(true);

      router.push(
        {
          pathname: router.pathname,
          query: { ...router.query, is_mobile: "1" },
        },
        undefined,
        { shallow: true }
      );
    }
  }, [router]);

  return isWebView;
};

export default useWebView;
