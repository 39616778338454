import React from 'react';
import { NavLink } from '../../components/NavLink/NavLink';
import { DropdownNav } from '../../components/DropdownNav/DropdownNav';

import './NavList.scss';

const defaultItem = {
  title: '',
  position: 'left',
  target: '_self',
  type: 'redirect',
};

export const NavList = (props) => {
  const {
    item = defaultItem,
    onMouseOver,
    onMouseLeave,
    isDesktop = false,
    isSelected = false,
    isShowChild = false,
    level,
  } = props;
  var itemClass;

  const levelUp = (n) => {
    return n + 1;
  };

  const switchClass = (level) => {
    switch (level) {
      case 0:
        itemClass = 'primary_nav_item';
        break;

      case 1:
        itemClass = 'secondary_nav_item';
        break;

      case 2:
        itemClass = 'tertiary_nav_item';
        break;

      default:
        itemClass = '';
    }

    return itemClass;
  };

  return (
    <li
      className={`${switchClass(level)} ${item.position === 'right' ? 'push_left' : ''
        } ${item.childItems ? 'has_dropdown' : ''}`}
    >
      <NavLink
        item={item}
        isWithStyle
        isSelected={isSelected}
        isDesktop={isDesktop}
        parentHasDropdown={item.childItems ? true : false}
        level={level}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />

      {item.childItems && (
        <>
          <DropdownNav
            level={levelUp(level)}
            menu={item.childItems}
            isSelected={isSelected}
            isShowChild={isShowChild}
            isDesktop={isDesktop}
          />
        </>
      )}
    </li>
  );
};
