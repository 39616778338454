import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DesktopNavbar } from './DesktopNavbar/DesktopNavbar';

const NavbarContext = createContext({});

export const Navbar = (props) => {
  const {
    isAuthenticated = false,
    authenticatedMenu,
    menu,
    activeMenu,
  } = props;

  let finalMenu = menu;
  const [isDesktop, setIsDesktop] = useState(false);

  if (isAuthenticated && authenticatedMenu) {
    if (!isDesktop) {
      finalMenu = authenticatedMenu.concat(menu)
    } else {
      finalMenu = menu.concat(authenticatedMenu);
    }
  }

  const activeLinks = getActiveMenuLinks(finalMenu, activeMenu);


  useEffect(() => {
    const resizeHandler = () => {
      const mobileBreakpoint = window.matchMedia('(max-width: 1199px)');
      const desktopBreakpoint = window.matchMedia('(min-width: 1200px)');

      if (mobileBreakpoint.matches) {
        setIsDesktop(false);
      } else if (desktopBreakpoint.matches) {
        setIsDesktop(true);
      }
    };

    resizeHandler();

    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <NavbarContext.Provider
      value={{
        ...props,
        finalMenu,
        activeLinks,
      }}
    >
      <DesktopNavbar isDesktop={isDesktop} />
    </NavbarContext.Provider>
  );
};

function getActiveMenuLinks(
  menu,
  activeMenuLink) {
  let activeMenus = [];

  activeMenuLink &&
    menu.forEach((menu) => {
      if (menu.link === activeMenuLink) {
        activeMenus.push(menu.link);
        return;
      }

      if (menu.childItems && Array.isArray(menu.childItems)) {
        const subMenu = getActiveMenuLinks(menu.childItems, activeMenuLink);
        if (subMenu.length) {
          activeMenus = activeMenus.concat(subMenu);
          activeMenus.push(menu.link);
          return;
        }
      }
    });
  return activeMenus;
}

export const useNavbarContext = () => useContext(NavbarContext);
